<template>
  <section>
    <section v-if="from === 'create'">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('mondaysOpeningHours') ? true : false"
              :success="
                !errors.first('mondaysOpeningHours') &&
                  weekDaysData.mondays != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="mondays opening hours"
              label="Mondays Opening Hours"
              name="mondaysOpeningHours"
              v-model="weekDaysData.mondays"
              class="w-full"
            />

            <span class="text-danger text-sm error-text">{{
              errors.first("mondaysOpeningHours")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('tuesdaysOpeningHours') ? true : false"
              :success="
                !errors.first('tuesdaysOpeningHours') &&
                  weekDaysData.tuesdays != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="tuesdays opening hours"
              label="Tuesdays Opening Hours"
              name="tuesdaysOpeningHours"
              v-model="weekDaysData.tuesdays"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("tuesdaysOpeningHours")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('wednesdaysOpeningHours') ? true : false"
              :success="
                !errors.first('wednesdaysOpeningHours') &&
                  weekDaysData.wednesdays != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="wednesdays opening hours"
              label="Wednesdays Opening Hours"
              name="wednesdaysOpeningHours"
              v-model="weekDaysData.wednesdays"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("wednesdaysOpeningHours")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('thursdaysOpeningHours') ? true : false"
              :success="
                !errors.first('thursdaysOpeningHours') &&
                  weekDaysData.thursdays != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="thursdays opening hours"
              label="Thursdays Opening Hours"
              name="thursdaysOpeningHours"
              v-model="weekDaysData.thursdays"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("thursdaysOpeningHours")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('fridaysOpeningHours') ? true : false"
              :success="
                !errors.first('fridaysOpeningHours') &&
                  weekDaysData.fridays != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="fridays opening hours"
              label="Fridays Opening Hours"
              name="fridaysOpeningHours"
              v-model="weekDaysData.fridays"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("fridaysOpeningHours")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('saturdaysOpeningHours') ? true : false"
              :success="
                !errors.first('saturdaysOpeningHours') &&
                  weekDaysData.saturdays != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="saturdays opening hours"
              label="Saturdays Opening Hours"
              name="saturdaysOpeningHours"
              v-model="weekDaysData.saturdays"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("saturdaysOpeningHours")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('sundaysOpeningHours') ? true : false"
              :success="
                !errors.first('sundaysOpeningHours') &&
                  weekDaysData.sundays != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="sundays opening hours"
              label="Sundays Opening Hours"
              name="sundaysOpeningHours"
              v-model="weekDaysData.sundays"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("sundaysOpeningHours")
            }}</span>
          </div>
        </vs-col>
      </vs-row>
    </section>

    <section v-else>
      <vs-input
        :danger="errors.first('mondaysOpeningHours') ? true : false"
        :success="
          !errors.first('mondaysOpeningHours') && weekDaysData.mondays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="mondays opening hours"
        label-placeholder="Mondays Opening Hours"
        name="mondaysOpeningHours"
        placeholder="Mondays Opening Hours"
        v-model="weekDaysData.mondays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("mondaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('tuesdaysOpeningHours') ? true : false"
        :success="
          !errors.first('tuesdaysOpeningHours') && weekDaysData.tuesdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="tuesdays opening hours"
        label-placeholder="Tuesdays Opening Hours"
        name="tuesdaysOpeningHours"
        placeholder="Tuesdays Opening Hours"
        v-model="weekDaysData.tuesdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("tuesdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('wednesdaysOpeningHours') ? true : false"
        :success="
          !errors.first('wednesdaysOpeningHours') &&
            weekDaysData.wednesdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="wednesdays opening hours"
        label-placeholder="Wednesdays Opening Hours"
        name="wednesdaysOpeningHours"
        placeholder="Wednesdays Opening Hours"
        v-model="weekDaysData.wednesdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("wednesdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('thursdaysOpeningHours') ? true : false"
        :success="
          !errors.first('thursdaysOpeningHours') && weekDaysData.thursdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="thursdays opening hours"
        label-placeholder="Thursdays Opening Hours"
        name="thursdaysOpeningHours"
        placeholder="Thursdays Opening Hours"
        v-model="weekDaysData.thursdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("thursdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('fridaysOpeningHours') ? true : false"
        :success="
          !errors.first('fridaysOpeningHours') && weekDaysData.fridays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="fridays opening hours"
        label-placeholder="Fridays Opening Hours"
        name="fridaysOpeningHours"
        placeholder="Fridays Opening Hours"
        v-model="weekDaysData.fridays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("fridaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('saturdaysOpeningHours') ? true : false"
        :success="
          !errors.first('saturdaysOpeningHours') && weekDaysData.saturdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="saturdays opening hours"
        label-placeholder="Saturdays Opening Hours"
        name="saturdaysOpeningHours"
        placeholder="Saturdays Opening Hours"
        v-model="weekDaysData.saturdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("saturdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('sundaysOpeningHours') ? true : false"
        :success="
          !errors.first('sundaysOpeningHours') && weekDaysData.sundays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="sundays opening hours"
        label-placeholder="Sundays Opening Hours"
        name="sundaysOpeningHours"
        placeholder="Sundays Opening Hours"
        v-model="weekDaysData.sundays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("sundaysOpeningHours")
      }}</span>
    </section>
  </section>
</template>

<script>
export default {
  name: "ClinicOpeningHours",
  props: ["weekDaysData", "from"],
  inject: ["$validator"]
};
</script>

<style scoped>
.error-text {
  font-size: 0.65rem !important;
  padding: 2px 4px 4px;
  display: block;
  margin-top: -6px;
}
</style>
