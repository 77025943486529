<template>
  <onboardClinic
    backRoute="SuperAdminClinic"
    :doctorClinicId="franchiseId"
    v-if="franchiseId"
  />
</template>

<script>
import onboardClinic from "../../components/derma-specialist/onboardClinic.vue";

export default {
  name: "CreateFranchiseClinic",
  components: {
    onboardClinic,
  },
  data: () => ({
    franchiseId: "",
  }),
  created() {
    this.franchiseId = this.$route.params.franchiseId;
  },
};
</script>
